import {
 createLocalizedPathnamesNavigation,
 Pathnames
} from 'next-intl/navigation';

export const locales = ['en', 'de'] as const;
export const localePrefix = 'always'; // Default

// The `pathnames` object holds pairs of internal
// and external paths, separated by locale.
export const pathnames = {
 // If locales use different paths, you can
 // specify each external path per locale.
 '/order/addresses': {
  en: '/order/addresses',
  de: '/auftrag/adressen'
 },
 '/order/confirm': {
  en: '/order/confirm',
  de: '/auftrag/bestaetigung'
 },
 '/order/contact': {
  en: '/order/contact',
  de: '/auftrag/kontakt'
 },
 '/order/invoice': {
  en: '/order/invoice',
  de: '/auftrag/rechnung'
 },
 '/order/screedcheck': {
  en: '/order/screedcheck',
  de: '/auftrag/estrichprobe'
 },
 '/order/thanks': {
  en: '/order/thanks',
  de: '/auftrag/danke'
 },
 '/order': {
  en: '/order',
  de: '/auftrag'
 },
 '/appointment': {
  en: '/appointment',
  de: '/termin'
 },
 '/status': {
  en: '/status',
  de: '/status'
 },
 '/maintenance': {
   en: '/maintenance',
   de: '/wartung'
 },
 '/': {
    en: '/',
    de: '/'
 }
} satisfies Pathnames<typeof locales>;

export const { Link, redirect, usePathname, useRouter, getPathname } =
 createLocalizedPathnamesNavigation({ locales, localePrefix, pathnames });