"use client";
import { useEffect } from "react";

const CookiebotScript = () => {
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://consent.cookiebot.com/uc.js";
        script.setAttribute("data-cbid", "db4861f9-5a07-42b2-8246-571f47c8bf71");
        script.setAttribute("data-blockingmode", "auto" );
        script.setAttribute("type", "text/javascript");
        script.async = true;
        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        };
    }, []);

    return null;
}

export default CookiebotScript;